import Swal from 'sweetalert2';
import { find, isUndefined } from 'lodash';
import './estilo.css';
import { deleteRol, deleteToken, deleteUserData } from '../api/Account';

export const simpleMessage = (text, icon) => {
	// Swal.fire(text, "", icon);
	Swal.fire({
		title: text,
		icon: icon,
		customClass: { container: 'my-swal', popup: 'border' }
	});
};

export const completeMessage = (title, text, icon) => {
	Swal.fire(title, text, icon);
};

export const oSVersion = () => {
	var OSName = 'Unknown OS';
	if (navigator.appVersion.indexOf('Win') !== -1) OSName = 'WINDOWS';
	if (navigator.appVersion.indexOf('Mac') !== -1) OSName = 'MACOS';
	if (navigator.appVersion.indexOf('X11') !== -1) OSName = 'UNIX';
	if (navigator.appVersion.indexOf('Linux') !== -1) OSName = 'LINUX';
	return OSName;
};

export const navigatorVersion = () => {
	var sBrowser,
		sUsrAg = navigator.userAgent;
	if (sUsrAg.indexOf('Edg') > -1) {
		sBrowser = 'Microsoft Edge';
	} else if (sUsrAg.indexOf('Chrome') > -1) {
		sBrowser = 'Google Chrome';
	} else if (sUsrAg.indexOf('Safari') > -1) {
		sBrowser = 'Apple Safari';
	} else if (sUsrAg.indexOf('Opera') > -1) {
		sBrowser = 'Opera';
	} else if (sUsrAg.indexOf('Firefox') > -1) {
		sBrowser = 'Mozilla Firefox';
	} else if (sUsrAg.indexOf('MSIE') > -1) {
		sBrowser = 'Microsoft Internet Explorer';
	}

	return sBrowser;
};

export function validateCedula(cedula) {
	let re = new RegExp('^[0-9]{3}-[0-9]{6}-[0-9]{4}[A-Za-z]$');
	return re.test(cedula);
}

export function isAccess(access, permiso) {
	let result = find(access, { description: permiso }, 'isEnable');
	return result ? result.isEnable : false;
}

export const getUserLocation = async () => {
	return new Promise((resolve, reject) => {
		navigator.geolocation.getCurrentPosition(
			({ coords }) => {
				resolve([coords.longitude, coords.latitude]);
			},
			(err) => {
				console.log('No se pudo obtener Localizacion');
				console.log(err);
				reject();
			}
		);
	});
};

export const getRuta = () => {
	const { REACT_APP_ROUTE, REACT_APP_PROD_ROUTE } = process.env;
	let ruta = '';
	if (process.env.NODE_ENV === 'production') {
		ruta = REACT_APP_PROD_ROUTE;
	} else {
		ruta = REACT_APP_ROUTE;
	}
	return ruta;
};

export const guid = () => {
	return 'yxyx'.replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c === 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
};

export const getController = () => {
	let ruta = '';
	if (process.env.NODE_ENV === 'production') {
		ruta = 'https://www.credihouse.online/';
	} else {
		ruta = 'https://localhost:44460/';
	}
	return ruta;
};

export const errorResponse = (response) => {
	if (response.status === 401) {
		deleteToken();
		deleteUserData();
		deleteRol();
		return 'Unauthorized';
	} else if (response.status === 400) {
		if (response.data.message === 'eX01') {
			deleteToken();
			deleteUserData();
			deleteRol();
			return 'Sesión activa en otro dispositivo';
		} else {
			return response.data.message;
		}
	} else if (response.status === 404) {
		return 'Recurso no Encontrado';
	} else if (response.status === 500) {
		return 'Error de Servidor';
	} else if (response.status === 0) {
		return 'No se pudo conectar con el servidor';
	}
};

export const generarNuevoColor = () => {
	var simbolos, color;
	simbolos = '0123456789ABCDEF';
	color = '#';

	for (var i = 0; i < 6; i++) {
		color = color + simbolos[Math.floor(Math.random() * 16)];
	}

	return color;
};

export const calculoSeguro = (monto) => {
	if (monto <= 5000) {
		return 150;
	} else if (monto > 5000 && monto <= 10000) {
		return 200;
	} else {
		return 250;
	}
};

export const num_Letras = (cedula) => {
	let numCedula = '';
	for (let index = 0; index < cedula.length; index++) {
		let char = charLetra(cedula[index]);
		if (isUndefined(char)) {
			numCedula += cedula[index];
		} else {
			numCedula += `${char}, `;
		}
	}
	return numCedula;
};

export const charLetra = (char) => {
	switch (char) {
		case '1':
			return 'UN0';
		case '2':
			return 'DOS';
		case '3':
			return 'TRES';
		case '4':
			return 'CUATRO';
		case '5':
			return 'CINCO';
		case '6':
			return 'SEIS';
		case '7':
			return 'SIETE';
		case '8':
			return 'OCHO';
		case '9':
			return 'NUEVE';
		case '0':
			return 'CERO';
		case '-':
			return 'GUION';
	}
};
