import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUsers,
  faPaste,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import { List, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

import { getRuta, isAccess } from "../../helpers/Helpers";
import { DataContext } from "../../context/DataContext";
import "./styles.css";
import { Stack } from "@mui/system";
import { Configurations } from "./Configurations";
import Security from "./Security";
import Administracion from "./Administracion";

const NavLinks = ({ drawerIsOpen }) => {
  let rout = getRuta();
  const { access } = useContext(DataContext);

  return (
    <div style={{ overflow: "hidden" }}>
      <Stack spacing={0.7} padding={1.1} justifyContent="flex-start">
        {/* Home */}
        <NavLink
          to={`${rout}/`}
          className={(navData) => (navData.isActive ? "active" : "inactive")}
          end
        >
          <Stack spacing={2} direction="row" alignItems={"center"}>
            <FontAwesomeIcon
              icon={faHome}
              style={{
                fontSize: 22,
              }}
            />
            <Typography>Panel Principal</Typography>
          </Stack>
        </NavLink>

        <hr />

        {/* Clients */}
        {isAccess(access, "CLIENTS VER") ? (
          <>
            <NavLink
              to={`${rout}/clients`}
              className={(navData) =>
                navData.isActive ? "active" : "inactive"
              }
            >
              <Stack spacing={2} direction="row" alignItems={"center"}>
                <FontAwesomeIcon
                  icon={faUsers}
                  style={{
                    fontSize: 22,
                  }}
                />
                <Typography>Clientes</Typography>
              </Stack>
            </NavLink>
            <hr />
          </>
        ) : (
          <></>
        )}

        {/* Credits */}
        {isAccess(access, "CREDITS VER") ? (
          <>
            <NavLink
              to={`${rout}/credits`}
              className={(navData) =>
                navData.isActive ? "active" : "inactive"
              }
            >
              <Stack spacing={2} direction="row" alignItems={"center"}>
                <FontAwesomeIcon
                  icon={faCreditCard}
                  style={{
                    fontSize: 22,
                  }}
                />
                <Typography>Créditos</Typography>
              </Stack>
            </NavLink>
            <hr />
          </>
        ) : (
          <></>
        )}
{/* 
        Admon
        {isAccess(access, "ADMON VER") ? (
          <>
            <Administracion drawerIsOpen={drawerIsOpen} />
            <hr />
          </>
        ) : (
          <></>
        )} */}

        {/* Reports */}
        {isAccess(access, "REPORTS VER") ? (
          <>
            <NavLink
              to={`${rout}/reports`}
              className={(navData) =>
                navData.isActive ? "active" : "inactive"
              }
            >
              <Stack spacing={2} direction="row" alignItems={"center"}>
                <FontAwesomeIcon
                  icon={faPaste}
                  style={{
                    fontSize: 22,
                  }}
                />
                <Typography>Reportes</Typography>
              </Stack>
            </NavLink>
            <hr />
          </>
        ) : (
          <></>
        )}

        {/* Security */}
        {isAccess(access, "SECURITY VER") ? (
          <>
            <Security drawerIsOpen={drawerIsOpen} />
            <hr />
          </>
        ) : (
          <></>
        )}

        {/* Configuration */}
        {isAccess(access, "CONFIG VER") ? (
          <>
            <Configurations drawerIsOpen={drawerIsOpen} />
            <hr />
          </>
        ) : (
          <></>
        )}
      </Stack>
    </div>
  );
};

export default NavLinks;
